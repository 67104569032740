// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require('channels')
require('jquery');
require('@fortawesome/fontawesome-free');
require('bootstrap');
require('admin-lte');
require("admin-lte/plugins/toastr/toastr.min");
require("chartkick")
require("chart.js")
require('select2')
require('bootstrap-datepicker')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// init toastr
import toastr from 'toastr';
global.toastr = toastr;

global.toastr.options.closeButton = true;
global.toastr.options.preventDuplicates = true;
//

// TOOLTIPS
$(document).on('turbolinks:load', function(){
  // Tooltips en íconos
  $('[data-toggle="tooltip"]').tooltip()
  
  $('[data-toggle="tooltip"]').on('click', function(){
    $(this).tooltip('hide')
  })
  
  // Select2. para elementos select con buscador
  $('select').select2({
    theme: 'bootstrap4'
  });

  // Datepicker y su traducción
  $.fn.datepicker.dates['es'] = {
    days:["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"],
    daysShort:["Dom","Lun","Mar","Mié","Jue","Vie","Sáb"],
    daysMin:["Do","Lu","Ma","Mi","Ju","Vi","Sa"],
    months:["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
    monthsShort:["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
    today:"Hoy",
    clear: "Borrar",
    format: "dd/mm/yyyy",
    titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
    weekStart: 1
  };

  $('.datepicker').datepicker({ 
    format: 'dd/mm/yyyy',
    language: 'es',
    todayHighlight: true
  });

  //modales bootstrap
  $(".users_modal").click(function(){
    $("#users_modal").modal("show");
  });


  // Procedure form
  if($('#procedure_procedure_type').val() == 'tratamiento'){
    $('#organism-to-control-js').removeClass('d-none');
  }

  $('#procedure_procedure_type').on('select2:select', function(e){
    var value = e.params.data.element.value
    if(value == 'tratamiento'){
      $('#organism-to-control-js').removeClass('d-none');
    }else{
      $('#organism-to-control-js').addClass('d-none');
    }
  })
  //

  // Work order form
  if($('#work_order_procedure_id').children("option:selected").attr('procedure_type') == 'tratamiento'){
    $('#concentracion-js').removeClass('d-none');
  }

  $('#work_order_procedure_id').on('select2:select', function(e){
    var value = e.params.data.element.attributes.procedure_type.value
    if(value == 'tratamiento'){
      $('#concentracion-js').removeClass('d-none');
    }else{
      $('#concentracion-js').addClass('d-none');
    }
  })
  //

  // required: true with custom message
  $('form input[type=text],textarea').on('change invalid', function() {
    var textfield = $(this).get(0);
    textfield.setCustomValidity('');
    
    if (!textfield.validity.valid) {
      textfield.setCustomValidity('Debe completar este campo');
    }
});
})